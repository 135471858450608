import * as basicScroll from 'basicscroll';
import {createSwiper} from './swiper';

/**
 * Animations Class
 * you can define all js animation here, like scroll animations
 *
 *
 * usage of basicScroll : @see https://github.com/electerious/basicScroll#usage
 *  const anim = basicScroll.create({
 elem: document.querySelector('.block-home-subheader'), // element position's to listen
 from: 'top-top', // enter scene target
 to: 'bottom-middle', // leave scene target
 direct: true // where props are applied, can be false (global) / true (elem) / Node (node element of your choice)
 track: true, // Track window size changes.
 props: { // can define css var to animate css property on scroll
 '--image-position': {
 from: 0,
 to: 100
 },
 },
 // with inside/outside callback you can add/remove css class on scene enter/leave
 inside: (instance, percentage, props) => {
 console.log('viewport is inside from and to')
 },
 outside: (instance, percentage, props) => {
 console.log('viewport is outside from and to')
 }
 });

 anim.start();
 */
export default class Animations {
    static init() {
        this.subHeaderHero();
        this.projectsSlider();
        this.pullQuoteAutoScroll();
    }

    static subHeaderHero() {
        const subHeader = document.querySelector('.block-home-subheader');
        if (subHeader) {
            /**
             * create and configure basicScroll animation's scene
             */
            const anim = basicScroll.create({
                elem: subHeader,
                from: 'top-top',
                to: 'bottom-middle',
                track: true,
                inside: (instance, percentage, props) => {
                    const currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                    const sceneStartPosition = instance.getData().from.value;
                    const sceneSubHeader = instance.getData().elem;
                    const pictures = sceneSubHeader.querySelectorAll('.hero-image');

                    if (currentScrollPosition > sceneStartPosition) {
                        pictures.forEach(picture => {
                            picture.classList.add('hero-image--animated');
                        });
                    }
                },
                outside: (instance, percentage, props) => {
                    const sceneSubHeader = instance.getData().elem;
                    const pictures = sceneSubHeader.querySelectorAll('.hero-image');
                    pictures.forEach(picture => {
                        picture.classList.remove('hero-image--animated');
                    });
                },
            });

            anim.start();
        }
    }

    /**
     * Slider of projects on the home and projects page
     *
     * Animate on mouse hover for desktop and become slick slider on mobile
     */
    static projectsSlider() {
        const projectsSections = document.querySelectorAll('.projectsSection');
        if (!projectsSections.length) {
            return;
        }

        const defaultConfig = {
            slideClass: 'projectsSection-item', // css class of slides element
            wrapperClass: 'projectsSection-carousel', // css class of slider wrapper
            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
        };

        projectsSections.forEach(projectsSection => {
            const projectsSectionOffset = projectsSection.querySelector('.projectsSection-offset');
            const customConfig = projectsSectionOffset.dataset.swiperCustomConfig ? JSON.parse(projectsSectionOffset.dataset.swiperCustomConfig) : {};

            createSwiper(projectsSectionOffset, {
                ...defaultConfig,
                ...customConfig,
                keyboard: {
                    enabled: true,
                },
                pagination: {
                    clickable: true,
                },
                navigation: {
                    prevEl: projectsSection.querySelector('.pagination-control--prev'),
                    nextEl: projectsSection.querySelector('.pagination-control--next'),
                },
            });
        });
    }

    /**
     * Pull quote block
     *
     * enable animation of pullquote text
     * auto scroll from left for odd child and from right for even child
     */
    static pullQuoteAutoScroll() {
        const pullQuotes = document.querySelectorAll('.single-project .wp-block-pullquote');

        pullQuotes.forEach((pullQuote, key) => {
            // add animation's ready class to initiate css and start animation
            // use JS to add animation's css to prevent display bug if JS is not correctly loaded
            pullQuote.classList.add('wp-block-pullquote--animation-ready');

            // Add reverse class for all pair pullquote bloc in the page
            if ((key + 1) % 2 === 0) {
                pullQuote.classList.add('wp-block-pullquote--reverse');
            }
        });
    }
}
